.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
position: relative;
width: fit-content;
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #000;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
@keyframes spin {
to {
    transform: rotate(360deg);
}
}